import React, { useEffect, useRef, useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import AuthDataService from "../../services/auth.dataservice";
import { useTranslation } from 'react-i18next';
import OrderBackButton from './OrderBackButton';
import { CarouselStep } from '../../utils/CarouselSteps';
import { useFormik } from 'formik';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import { RegisterRequest, defaultRegisterRequest } from '../../models/RegisterRequest';
import { validateRegisterForm, hasAnyFormErrors } from '../../utils/formValidation';
import { setError } from '../../store/slices/errorSlice';
import { AuthenticateReponse } from '../../models/AuthenticateResponse';
import { ContactInfoSelectionMode } from './ContactInfoSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Country } from '../../models/Country';
import countryDataservice from '../../services/country.dataservice';
import { ContactInfo } from '../../models/ContactInfo';
import contactInfoDataservice from '../../services/contactInfo.dataservice';
import { ResponseWithPossibleError } from '../../models/ResponseWithPossibleError';

interface Props {
    reCaptchaKey: string,
    registerMode: RegisterMode,
    contactInfo: ContactInfo | null,
    onModeChange: (mode: ContactInfoSelectionMode) => void,
}

export enum RegisterMode {
    EntirelyNewAccount = 0,
    OnlyNewContactInfo = 1,
    EditContactInfo = 2
}

const Register: React.FunctionComponent<Props> = ({ reCaptchaKey, registerMode, onModeChange, contactInfo }: Props) => {
    const { t, i18n } = useTranslation(); const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const captchaRef = useRef<ReCAPTCHA>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [countryList, setCountryList] = useState<Country[] | null>(null);

    useEffect(() => {
        loadCountries();
    }, []);

    const loadCountries = () => {
        countryDataservice.getAll().then((response) => {
            setCountryList(response.data);
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(t("misc.genericError")));
        });
    }
    const getInitFormValues = (): RegisterRequest => {
        if (registerMode === RegisterMode.EditContactInfo && contactInfo && contactInfo.id && contactInfo.id !== 0) {
            defaultRegisterRequest.contactInfoId = contactInfo.id
            defaultRegisterRequest.companyName = contactInfo.companyName;
            defaultRegisterRequest.companyStreet = contactInfo.companyStreet;
            defaultRegisterRequest.companyZip = contactInfo.companyZip;
            defaultRegisterRequest.companyPlace = contactInfo.companyPlace;
            defaultRegisterRequest.companyCountry = contactInfo.companyCountry;
            defaultRegisterRequest.companyIdNumber = contactInfo.companyIdNumber;
            defaultRegisterRequest.website = contactInfo.website;
            defaultRegisterRequest.title = contactInfo.title ? contactInfo.title : "";
            defaultRegisterRequest.salutation = contactInfo.salutation;
            defaultRegisterRequest.companyTelephone = contactInfo.companyTelephone ? contactInfo.companyTelephone : "";
            defaultRegisterRequest.firstname = contactInfo.firstname;
            defaultRegisterRequest.lastname = contactInfo.lastname;
            defaultRegisterRequest.function = contactInfo.function ? contactInfo.function : "";
            defaultRegisterRequest.email = contactInfo.email ? contactInfo.email : "";
            defaultRegisterRequest.telephone = contactInfo.telephone;
        }
        return defaultRegisterRequest;
    };

    const formik = useFormik<RegisterRequest>({
        initialValues: getInitFormValues(),
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (formValues, { setErrors }) => {
            const errors = validateRegisterForm(formValues, registerMode);
            if (hasAnyFormErrors(errors)) {
                setErrors(errors);
                return;
            }
            setIsLoading(true);
            if (registerMode === RegisterMode.EntirelyNewAccount) {
                AuthDataService.registerNewUser({ ...formValues, captchaToken }, i18n.language).then((response) => {
                    handleRegisterNewUser(response);
                }).catch(() => {
                    dispatch(setError(t("misc.genericError")));
                    captchaRef.current?.reset();
                    setIsLoading(false);
                });
            } else if (registerMode === RegisterMode.OnlyNewContactInfo) {
                AuthDataService.registerNewContactInfo({ ...formValues, captchaToken }, order, i18n.language).then((response) => {
                    handleRegisterNewContactInfo(response);
                }).catch(() => {
                    dispatch(setError(t("misc.genericError")));
                    captchaRef.current?.reset();
                    setIsLoading(false);
                });
            } else if (registerMode === RegisterMode.EditContactInfo) {
                contactInfoDataservice.put({ ...formValues, captchaToken }, order, i18n.language).then((response) => {
                    handleEditContactInfo(response);
                }).catch(() => {
                    dispatch(setError(t("misc.genericError")));
                    captchaRef.current?.reset();
                    setIsLoading(false);
                });
            } else {
                dispatch(setError(t("misc.genericError")));
                captchaRef.current?.reset();
                setIsLoading(false);
            }
        },
    });

    const handleRegisterNewUser = (response: AuthenticateReponse) => {
        if (!response.hasError && response.jwtToken) {
            localStorage.setItem("order-esg2go-user", response.jwtToken);
            dispatch(updateOrder({
                ...order,
                user: { id: response.id, email: response.email, jwtToken: response.jwtToken },
                carouselIndex: CarouselStep.ContactInfoSelection
            }));
            setErrorMessage("");
            setIsLoading(false);
        } else {
            setErrorMessage(response.message);
            captchaRef.current?.reset();
            setIsLoading(false);
        }
        dispatch(setError(""));
    }

    const handleRegisterNewContactInfo = (response: AuthenticateReponse) => {
        if (!response.hasError && response.jwtToken) {
            localStorage.setItem("order-esg2go-user", response.jwtToken);
            dispatch(updateOrder({
                ...order,
                user: { id: response.id, email: response.email, jwtToken: response.jwtToken },
                carouselIndex: CarouselStep.ContactInfoSelection
            }));
            setErrorMessage("");
            setIsLoading(false);
            onModeChange(ContactInfoSelectionMode.SelectExistingOne);
        } else {
            setErrorMessage(response.message);
            captchaRef.current?.reset();
            setIsLoading(false);
        }
        dispatch(setError(""));
    }

    const handleEditContactInfo = (response: ResponseWithPossibleError) => {
        if (!response.hasError) {
            setErrorMessage("");
            setIsLoading(false);
            onModeChange(ContactInfoSelectionMode.SelectExistingOne);
        } else {
            setErrorMessage(response.message);
            captchaRef.current?.reset();
            setIsLoading(false);
        }
        dispatch(setError(""));
    }

    return (
        <>
            {registerMode === RegisterMode.EntirelyNewAccount ? <h3 className='mb-3'>{t("misc.register")}</h3> : null}
            <Form onSubmit={formik.handleSubmit}>
                {registerMode === RegisterMode.EntirelyNewAccount ?
                    <>
                        <Form.Label className='mt-3'>{t("order.userAuthentication.email")}*</Form.Label>
                        <Form.Control
                            type="text"
                            name="userEmail"
                            onChange={formik.handleChange} value={formik.values.userEmail}
                            className={formik.errors.userEmail ? "is-invalid" : ""} />
                        {formik.errors.userEmail ? <Form.Control.Feedback type="invalid">{t(formik.errors.userEmail)}</Form.Control.Feedback> : null}

                        <br />
                        <hr />
                    </>
                    : null
                }
                <h5>{t("order.userAuthentication.company")}</h5>

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyName")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="companyName"
                    onChange={formik.handleChange} value={formik.values.companyName}
                    className={formik.errors.companyName ? "is-invalid" : ""} />
                {formik.errors.companyName ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyName)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyIdNumber")}</Form.Label>
                <Form.Control
                    type="text"
                    name="companyIdNumber"
                    onChange={formik.handleChange} value={formik.values.companyIdNumber}
                    className={formik.errors.companyIdNumber ? "is-invalid" : ""} />
                {formik.errors.companyIdNumber ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyIdNumber)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyStreet")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="companyStreet"
                    onChange={formik.handleChange} value={formik.values.companyStreet}
                    className={formik.errors.companyStreet ? "is-invalid" : ""} />
                {formik.errors.companyStreet ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyStreet)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyZip")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="companyZip"
                    onChange={formik.handleChange} value={formik.values.companyZip}
                    className={formik.errors.companyZip ? "is-invalid" : ""} />
                {formik.errors.companyZip ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyZip)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyPlace")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="companyPlace"
                    onChange={formik.handleChange} value={formik.values.companyPlace}
                    className={formik.errors.companyPlace ? "is-invalid" : ""} />
                {formik.errors.companyPlace ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyPlace)}</Form.Control.Feedback> : null}

                {
                    countryList ?
                        <>
                            <Form.Label className='mt-3'>{t("order.userAuthentication.companyCountry")}*</Form.Label>
                            <Form.Select name="companyCountry" className={formik.errors.companyCountry ? "is-invalid" : ""} onChange={formik.handleChange} value={formik.values.companyCountry}>
                                <option value="">{t("misc.defaultOption")}</option>
                                {countryList.map((country, index) => {
                                    return <option value={country.value} key={index}>{country.text}</option>
                                })}
                            </Form.Select>
                            {formik.errors.companyCountry ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyCountry)}</Form.Control.Feedback> : null}
                        </>
                        : null
                }

                <Form.Label className='mt-3'>{t("order.userAuthentication.website")}</Form.Label>
                <Form.Control
                    type="text"
                    name="website"
                    onChange={formik.handleChange} value={formik.values.website}
                    className={formik.errors.website ? "is-invalid" : ""} />
                    {formik.errors.website ? <Form.Control.Feedback type="invalid">{t(formik.errors.website)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.companyTelephone")}</Form.Label>
                <Form.Control
                    type="text"
                    name="companyTelephone"
                    onChange={formik.handleChange} value={formik.values.companyTelephone}
                    className={formik.errors.companyTelephone ? "is-invalid" : ""} />
                    {formik.errors.companyTelephone ? <Form.Control.Feedback type="invalid">{t(formik.errors.companyTelephone)}</Form.Control.Feedback> : null}

                <h5 className='mt-4'>{t("order.userAuthentication.contactPerson")}</h5>

                <Form.Label className='mt-3'>{t("order.userAuthentication.salutation")}*</Form.Label>
                <Form.Select
                    name="salutation"
                    onChange={formik.handleChange} value={formik.values.salutation}
                    className={formik.errors.salutation ? "is-invalid" : ""}>
                    <option value="">{t("misc.defaultOption")}</option>
                    <option value="Mister">{t("misc.mister")}</option>
                    <option value="Miss">{t("misc.miss")}</option>
                </Form.Select>
                {formik.errors.salutation ? <Form.Control.Feedback type="invalid">{t(formik.errors.salutation)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.title")}</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    onChange={formik.handleChange} value={formik.values.title}
                    className={formik.errors.title ? "is-invalid" : ""} />
                    {formik.errors.title ? <Form.Control.Feedback type="invalid">{t(formik.errors.title)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.firstname")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="firstname"
                    onChange={formik.handleChange} value={formik.values.firstname}
                    className={formik.errors.firstname ? "is-invalid" : ""} />
                {formik.errors.firstname ? <Form.Control.Feedback type="invalid">{t(formik.errors.firstname)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.lastname")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="lastname"
                    onChange={formik.handleChange} value={formik.values.lastname}
                    className={formik.errors.lastname ? "is-invalid" : ""} />
                {formik.errors.lastname ? <Form.Control.Feedback type="invalid">{t(formik.errors.lastname)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.function")}</Form.Label>
                <Form.Control
                    type="text"
                    name="function"
                    onChange={formik.handleChange} value={formik.values.function}
                    className={formik.errors.function ? "is-invalid" : ""} />
                    {formik.errors.function ? <Form.Control.Feedback type="invalid">{t(formik.errors.function)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.emailContactPerson")}</Form.Label>
                <Form.Control
                    type="text"
                    name="email"
                    onChange={formik.handleChange} value={formik.values.email}
                    className={formik.errors.email ? "is-invalid" : ""} />
                    {formik.errors.email ? <Form.Control.Feedback type="invalid">{t(formik.errors.email)}</Form.Control.Feedback> : null}

                <Form.Label className='mt-3'>{t("order.userAuthentication.telephone")}*</Form.Label>
                <Form.Control
                    type="text"
                    name="telephone"
                    onChange={formik.handleChange} value={formik.values.telephone}
                    className={formik.errors.telephone ? "is-invalid" : ""} />
                {formik.errors.telephone ? <Form.Control.Feedback type="invalid">{t(formik.errors.telephone)}</Form.Control.Feedback> : null}

                <ReCAPTCHA
                    key={i18n.language.toLowerCase()}
                    hl={i18n.language.toLowerCase()}
                    className="mt-4"
                    sitekey={reCaptchaKey}
                    ref={captchaRef}
                    onExpired={() => { captchaRef.current?.reset(); }}
                    onChange={(t: string | null) => { if (t != null) setCaptchaToken(t); }}
                />

                {
                    errorMessage || hasAnyFormErrors(formik.errors) ?
                        <div className="mt-4 error-display">
                            <Form.Control
                                className="is-invalid"
                                type="hidden" />
                            <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{ __html: (errorMessage ? t(errorMessage) : t("misc.formValidationError")) }}></Form.Control.Feedback>
                        </div> : null
                }

                {registerMode === RegisterMode.EntirelyNewAccount ?
                    <OrderBackButton toIndex={CarouselStep.ReferenceYear} />
                    :
                    <Button variant="danger" onClick={() => onModeChange(ContactInfoSelectionMode.SelectExistingOne)} className="mt-4 me-3">
                        <FontAwesomeIcon icon={faChevronLeft} /> {t("misc.back")}
                    </Button>
                }

                <Button type='submit' variant="primary" className="mt-4" disabled={isLoading} >
                    {registerMode === RegisterMode.EditContactInfo ? t("misc.apply") : t("misc.register")}
                    {isLoading ?
                        <span> <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /></span> : null
                    }
                </Button>
            </Form>
            <br />
        </>
    );
}

export default Register;
