import { AxiosResponse } from "axios";
import { http, httpLocalized } from "../http-common";
import { RegisterRequest } from "../models/RegisterRequest";
import { AuthenticateRequest } from "../models/AuthenticateRequest";
import { AuthenticateReponse } from "../models/AuthenticateResponse";
import { PasswordResetRequest } from "../models/PasswordResetRequest";
import { PasswordResetResponse } from "../models/PasswordResetResponse";
import { RequestPasswordResetRequest } from "../models/RequestPasswordResetRequest";
import { RequestPasswordResetResponse } from "../models/RequestPasswordResetResponse";
import { Order } from "../models/Order";
import { CheckTanRequest } from "../models/CheckTanRequest";

class AuthDataService {
    async authenticate(authenticateRequestModel: AuthenticateRequest, lang: string) {
        const { data } = await httpLocalized(lang).post<AuthenticateRequest, AxiosResponse<AuthenticateReponse>>("/auth/authenticate", authenticateRequestModel);
        return data;
    }
    async registerNewUser(registerRequestModel: RegisterRequest, lang: string) {
        const { data } = await httpLocalized(lang).post<AuthenticateRequest, AxiosResponse<AuthenticateReponse>>("/auth/register", registerRequestModel);
        return data;
    }

    async registerNewContactInfo(registerRequestModel: RegisterRequest, order: Order, lang: string) {
        const config = {
            headers: { Authorization: `Bearer ${order.user?.jwtToken}` }
        };
        const { data } = await httpLocalized(lang).post<AuthenticateRequest, AxiosResponse<AuthenticateReponse>>("/auth/register/contactInfo", registerRequestModel, config);
        return data;
    }

    async passwordReset(passwordResetModel: PasswordResetRequest, lang: string) {
        const { data } = await httpLocalized(lang).post<PasswordResetRequest, AxiosResponse<PasswordResetResponse>>("/auth/pwreset", passwordResetModel);
        return data;
    }

    async requestPasswordReset(passwordResetRequestModel: RequestPasswordResetRequest, lang: string) {
        const { data } = await httpLocalized(lang).post<RequestPasswordResetRequest, AxiosResponse<RequestPasswordResetResponse>>("/auth/pwreset/request", passwordResetRequestModel);
        return data;
    }

    async checkTan(checkTanRequest: CheckTanRequest) {
        const { data } = await http().post<CheckTanRequest, AxiosResponse<AuthenticateReponse>>("/auth/tan", checkTanRequest);
        return data;
    }  
}
export default new AuthDataService();